<template>
  <div>
    <b-container fluid>
      <b-row align-v="center" class="side-panel-header-sub">
        <b-col cols="1" style="text-align: center; padding: 0;">
          <span class="row-dots">...</span>
        </b-col>
        <b-col cols="10">
          <span>V130.101 - Adaptive Speed Control Instances</span>
        </b-col>
        <b-col cols="1" style="text-align: right;">
          <Avatar username="J R" :size="32" backgroundColor="#000" color="#ffffff" />
        </b-col>
      </b-row>
    </b-container>
    <div class="content">
      <b-container fluid>
        <b-row>
          <b-col cols="11">
            <div class="assetTopLabel">
              <span>Av. velocity ~ 30 kmph | Av. latency 1.1s</span>
            </div>
          </b-col>
          <b-col cols="1">
            <p v-pre class="knowledge-hexa"></p>
          </b-col>
        </b-row>
      </b-container>

      <div class="assetRows">
        <div class="assetRow odd">
          <b-container fluid>
            <b-row>
              <b-col cols="1" style="text-align: center; padding: 0;">
                <span class="row-dots">...</span>
              </b-col>
              <b-col cols="10">
                <div>7:10 AM 18 Jan 2020, anon21</div>
                <div>
                  -feature speed
                  <span class="highlight">cutoff</span> -velocity
                  <span class="highlight">~40kmph</span>, ~SSW -performance
                  <span class="highlight">latency 1.1s</span>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="assetRow even">
          <b-container fluid>
            <b-row>
              <b-col cols="1" style="text-align: center; padding: 0;">
                <span class="row-dots">...</span>
              </b-col>
              <b-col cols="10">
                <div>7:10 AM 18 Jan 2020, anon21</div>
                <div>
                  -feature speed
                  <span class="highlight">regain</span> -velocity
                  <span class="highlight">~20kmph</span>, ~SW -performance
                  <span class="highlight">latency 0.9s</span>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="assetRow odd">
          <b-container fluid>
            <b-row>
              <b-col cols="1" style="text-align: center; padding: 0;">
                <span class="row-dots">...</span>
              </b-col>
              <b-col cols="10">
                <div>7:09 AM 18 Jan 2020, anon21</div>
                <div>
                  -feature speed
                  <span class="highlight">cutoff</span> -velocity
                  <span class="highlight">~35kmph</span>, ~NNW -performance
                  <span class="highlight">latency 1.13s</span>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="assetRow even">
          <b-container fluid>
            <b-row>
              <b-col cols="1" style="text-align: center; padding: 0;">
                <span class="row-dots">...</span>
              </b-col>
              <b-col cols="10">
                <div>7:10 AM 18 Jan 2020, anon21</div>
                <div>
                  -feature speed
                  <span class="highlight">regain</span> -velocity
                  <span class="highlight">~20kmph</span>, ~NW -performance
                  <span class="highlight">latency 1.5s</span>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="assetRow odd">
          <b-container fluid>
            <b-row>
              <b-col cols="1" style="text-align: center; padding: 0;">
                <span class="row-dots">...</span>
              </b-col>
              <b-col cols="10">
                <div>
                  7:10 AM 18 Jan 2020, V130.101
                  <span
                    class="highlight"
                  >Defective Lightning fixed by ENG</span>
                </div>
              </b-col>
              <b-col cols="1">
                <p v-pre class="knowledge-hexa"></p>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="assetRow even">
          <b-container fluid>
            <b-row>
              <b-col cols="1" style="text-align: center; padding: 0;">
                <span class="row-dots">...</span>
              </b-col>
              <b-col cols="10">
                <div>
                  16:00 PM 16 Jan 2020, V130.101
                  <span
                    class="highlight"
                  >Debris cleared from broken branches by KRK</span>
                </div>
              </b-col>
              <b-col cols="1">
                <p v-pre class="knowledge-hexa"></p>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="assetRow odd">
          <b-container fluid>
            <b-row>
              <b-col cols="1" style="text-align: center; padding: 0;">
                <span class="row-dots">...</span>
              </b-col>
              <b-col cols="10">
                <div>
                  13:00 PM 17 Jan 2020, V130.101
                  <span
                    class="highlight"
                  >Asphalt crack repaired by BAM</span>
                </div>
              </b-col>
              <b-col cols="1">
                <p v-pre class="knowledge-hexa"></p>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="assetRow even">
          <b-container fluid>
            <b-row>
              <b-col cols="1" style="text-align: center; padding: 0;">
                <span class="row-dots">...</span>
              </b-col>
              <b-col cols="10">
                <div>
                  17:00 PM 17 Jan 2020, JV130.271
                  <span
                    class="highlight"
                  >Junction Prioritisation changed by SB@BAM</span>
                </div>
              </b-col>
              <b-col cols="1">
                <p v-pre class="knowledge-hexa"></p>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
export default {
  name: "asset",
  components: {
    Avatar,
  },
  computed: {},
};
</script>

<style scoped>
.side-panel-content {
  background: #262626;
}

.side-panel-header-sub {
  text-align: left;
  height: 47px;
  font-weight: bold;
  font-size: 0.9rem;
  background: white;
}

.content {
  padding: 0px;
  color: #949494;
  font-size: 0.85rem;
  overflow: auto;
  height: calc(100% - 46px);
}

.content p:before {
  content: "";
  width: 35px;
  height: 35px;
  display: block;
  float: left;
  margin-left: -52px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: top center;
}

.assetTopLabel {
  padding: 15px;
  padding-left: 0px;
}
.assetTopLabel span {
  padding: 10px;
  background: #ef3b39;
  color: #262626;
  border-radius: 13px;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
}

.assetRows {
  color: #989898;
}

.assetRows .highlight {
  color: #f3f3f3;
}

.assetRows .assetRow {
  padding-top: 5px;
  padding-bottom: 5px;
}

.assetRow + .even {
  background: #383838;
}

.assetRow + .odd {
  background: #262626;
}

.row-dots {
  font-size: 1.85em;
}

.knowledge-hexa {
  padding-top: 13px;
}

.knowledge-hexa:before {
  background: url("~@/assets/img/UI_KnowledgeHexa_Passive.svg");
}
</style>